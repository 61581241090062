import React from "react";
import { Button } from "../../../components/ui/button";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className='min-h-screen bg-black text-white p-8'>
      <div className='max-w-3xl mx-auto'>
        <h1 className='text-4xl font-bold mb-8'>Privacy Policy</h1>

        <div className='space-y-6'>
          <section>
            <h2 className='text-2xl font-semibold mb-4'>
              1. Information We Collect
            </h2>
            <p className='text-gray-300'>
              We collect information that you provide directly to us when using
              our services, including but not limited to your voice recordings,
              account information, and usage data.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>
              2. How We Use Your Information
            </h2>
            <p className='text-gray-300'>
              We use the collected information to provide, maintain, and improve
              our services, develop new features, and protect our users.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>3. Data Security</h2>
            <p className='text-gray-300'>
              We implement appropriate security measures to protect your
              personal information against unauthorized access, alteration,
              disclosure, or destruction.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>4. Contact Us</h2>
            <p className='text-gray-300'>
              If you have any questions about our Privacy Policy, please contact
              us at: privacy@anyonelabs.com
            </p>
          </section>
        </div>

        <div className='mt-8'>
          <Link to='/'>
            <Button variant='outline' className='text-blue-500 border-blue-500'>
              Back to Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
