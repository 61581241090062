import React from "react";
import { Button } from "../../../components/ui/button";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className='min-h-screen bg-black text-white p-8'>
      <div className='max-w-3xl mx-auto'>
        <h1 className='text-4xl font-bold mb-8'>Terms of Service</h1>

        <div className='space-y-6'>
          <section>
            <h2 className='text-2xl font-semibold mb-4'>
              1. Acceptance of Terms
            </h2>
            <p className='text-gray-300'>
              By accessing and using Anyone Labs services, you agree to be bound
              by these Terms of Service and all applicable laws and regulations.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>2. Use License</h2>
            <p className='text-gray-300'>
              We grant you a limited, non-exclusive, non-transferable license to
              use our services for personal, non-commercial purposes, subject to
              these Terms.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>
              3. User Responsibilities
            </h2>
            <p className='text-gray-300'>
              You are responsible for maintaining the confidentiality of your
              account and for all activities that occur under your account.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>
              4. Service Modifications
            </h2>
            <p className='text-gray-300'>
              We reserve the right to modify or discontinue our services at any
              time without notice. We shall not be liable to you or any third
              party for any modification, suspension, or discontinuance of the
              service.
            </p>
          </section>

          <section>
            <h2 className='text-2xl font-semibold mb-4'>5. Contact</h2>
            <p className='text-gray-300'>
              For any questions regarding these Terms of Service, please contact
              us at: terms@anyonelabs.com
            </p>
          </section>
        </div>

        <div className='mt-8'>
          <Link to='/'>
            <Button variant='outline' className='text-blue-500 border-blue-500'>
              Back to Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
