import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Button } from "./components/ui/button";
import { Mic } from "lucide-react";
import TermsOfService from "./pages/emily/terms";
import PrivacyPolicy from "./pages/emily/privacy";

const LandingPage = () => {
  return (
    <div className='min-h-screen bg-black text-white flex flex-col justify-center items-center'>
      <main className='text-center p-8 flex flex-col items-center'>
        <div className='mb-8'>
          <Mic className='w-10 h-10 text-blue-500 animate-pulse' />
        </div>

        <h1 className='text-4xl font-bold mb-4'>Anyone Labs</h1>

        <p className='text-xl mb-8 max-w-md mx-auto'>
          An app studio that makes voice AI products.
        </p>

        <div className='mb-12 space-y-2'>
          <h1 className='text-2xl font-semibold text-blue-500'>Emily</h1>
          <p className='text-lg text-gray-300'>
            Your AI Personal Receptionist - Smart Voicemail Management
          </p>
          <p className='text-sm text-gray-400'>iOS only</p>
        </div>

        <div className='space-y-4'>
          <Button
            variant='outline'
            size='lg'
            className='w-full max-w-xs border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white transition-colors duration-300'
          >
            Join the Experiment
          </Button>

          <p className='text-sm text-gray-500'>Coming soon. Are you ready?</p>
        </div>
      </main>

      <footer className='absolute bottom-4 text-center text-gray-600 text-xs'>
        <p>&copy; 2024 Anyone Labs</p>
        <div className='mt-2 space-x-4'>
          <Link to='/emily/terms' className='hover:text-gray-400'>
            Terms of Service
          </Link>
          <Link to='/emily/privacy' className='hover:text-gray-400'>
            Privacy Policy
          </Link>
          <a href='mailto:team@anyonelabs.com' className='hover:text-gray-400'>
            Contact
          </a>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/emily/terms' element={<TermsOfService />} />
      <Route path='/emily/privacy' element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default App;
